
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  computed,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import { useStore } from "vuex";
import { translate } from "@/hooks/TranslateService";
import { IUser } from "@/store/modules/valored/UserModule";
import { ICompany } from "@/store/modules/valored/CompanyModule";
import { Actions } from "@/store/enums/StoreEnums";
import { successMessage } from "@/core/services/AlertService";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const { t } = useI18n();
    const submitButton1 = ref<HTMLElement | null>(null);
    const updateEmailButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);

    const profileDetailsValidator = Yup.object().shape({
      user_name: Yup.string().required().label("Username"),
    });

    const changeEmail = Yup.object().shape({
      email: Yup.string().required(translate("EMAIL_REQUIRED")).email(translate("VALID_EMAIL")).label("Email"),
      password: Yup.string().required(translate("CONFIRM_PASSWORD_REQUIRED")).label("Password"),
    });

    const changePassword = Yup.object().shape({
      currentpassword: Yup.string().required(translate("CONFIRM_PASSWORD_REQUIRED")).label(translate("CONFIRM_PASSWORD")),
      newpassword: Yup.string()
        .min(6, translate("REGISTRATION_PASSWORD_PLACEHOLDER"))
        .required(translate("PASSWORD_REQUIRED"))
        .label("Password"),
      confirmpassword: Yup.string()
        .min(4)
        .required()
        .oneOf([Yup.ref("newpassword"), null], translate("PASSWORD_MUST_MATCH"))
        .label("Password Confirmation"),
    });

    const updateUserProfile = (values) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        if (values.ruolo_aziendale !== null && values.ruolo_aziendale.trim() === "") {
          values.ruolo_aziendale = null;
        }
        if (values.telefono !== null && values.telefono.trim() === "") {
          values.telefono = null;
        }

        store
          .dispatch(Actions.UPDATE_USER_PROFILE, values)
          .then(() => {
            successMessage(t("FEEDBACK_USERNAME_CHANGE_SUCCESSFUL"));
          })
          .finally(() => {
            submitButton1.value?.removeAttribute("data-kt-indicator");
          });
      }
    };

    const updateEmail = (values, { resetForm }) => {
      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        store
          .dispatch(Actions.UPDATE_EMAIL, values)
          .then(() => {
            successMessage(t("EMAIL_SUCCESS_UPDATED_MESSAGE"));
            emailFormDisplay.value = false;
            resetForm();
          })
          .finally(() => {
            updateEmailButton.value?.removeAttribute("data-kt-indicator");
          });
      }
    };

    const updatePassword = (values, { resetForm }) => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        store
          .dispatch(Actions.UPDATE_PASSWORD, values)
          .then(() => {
            successMessage(t("PASSWORD_SUCCESS_UPDATED_MESSAGE"));
            passwordFormDisplay.value = false;
            resetForm();
          })
          .finally(() => {
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
          });
      }
    };

    onMounted(() => {
      setTimeout(() => {
        store.dispatch(
          Actions.CHANGE_CURRENT_YEARLY_PERIOD,
          localStorage.getItem("to_year")
        );
      }, 1000);
      
      setCurrentPageBreadcrumbs("Settings", ["Account"]);
    });

    const store = useStore();
    const accountInfo = reactive({
      userProfile: computed(() => store.getters.currentUser as IUser),
      companyProfile: computed(
        () => store.getters.getCompanyProfile as ICompany
      ),
    });

    const userName = ref(accountInfo.userProfile.user_name);
    const defaultUserName = ref(userName.value);

    const userRole = ref(accountInfo.userProfile.ruolo_aziendale);
    const defaultUserRole = ref(userRole.value);

    const userPhone = ref(accountInfo.userProfile.telefono);
    const defaultUserPhone = ref(userPhone.value);

    const resetFields = () => {
      userName.value = defaultUserName.value;
      userRole.value = defaultUserRole.value;
      userPhone.value = defaultUserPhone.value;
    }

    return {
      submitButton1,
      updateUserProfile,
      emailFormDisplay,
      passwordFormDisplay,
      profileDetailsValidator,
      changeEmail,
      changePassword,
      updateEmailButton,
      updatePasswordButton,
      updateEmail,
      updatePassword,
      translate,
      ...toRefs(accountInfo),
      userName, 
      userRole,
      userPhone,
      resetFields
    };
  },
});
