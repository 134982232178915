import { useI18n } from "vue-i18n";

export const translate = (text): string => {
  const { t, te } = useI18n();
  if (te(text)) {
    return t(text);
  } else {
    return text;
  }
};

export const translateKey = (key): string => {
  const { locale } = useI18n();
  if (locale.value == "it") return key;
  else return key + "_" + locale.value;
  return key;
};

export default { translate, translateKey };
